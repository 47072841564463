




















import { PhotoObject } from "@/interfaces/models";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Gallery extends Vue {
  @Prop({ required: true, default: "" }) title?: string;
  @Prop({ required: true }) display_names?: boolean;
  @Prop({ required: true, default: () => [] }) photos?: PhotoObject[];
  @Prop({ default: "white" }) color?: string;
  activeIndex = 0;
  visible = false;

  get images(): { title: string; src: string }[] {
    const images =
      this.photos?.map((item: any) => {
        return {
          title: item.photo.name,
          src: this.$root.$data.host + item.photo.image.url
        };
      }) ?? [];
    return images ?? [];
  }

  openLightbox(index: number): void {
    this.activeIndex = index;
    this.visible = true;
  }

  hideLightbox(): void {
    this.visible = false;
  }
}
